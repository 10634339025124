<template lang="pug">
include ../../configs/template
div
  //now not used
    div.vx-card.disable-rounded-right.p-2.mb-1
      div.card-header
        div.card-title
          h4.text-center {{ $t('addUbikey') }}
      div.card-content.pt-3
        AddUbikeyToLogin(:fingerRegistration="registerFinger")

  div.vx-card.p-2
    div.card-header
      div.card-title
        h4.text-center {{ $t('addUser') }}
    div.card-content.pt-7
      b-form(@submit.prevent="createUser")
        div.w-full.permissionChoiceContent
          div.flex.items-end.align-items-center.border-bottom.pb-4
            v-icon(color="#475F7B") mdi-lock-outline
            span.font-normal.text-lg.leading-none {{ $t('permissions') }}
          +radio-group-validation('body.userprofile.main_group', 'permissionsList', '', '"text"', '["required"]')
        div.row.mt-5.mx-1
          div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
            +field-validation('body.last_name', 'lastName', '["required", validationCountry, "maxLength"]')
          div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
            +field-validation('body.first_name', 'name', '["required", validationCountry, "maxLength"]')
          div.col-xs-12.col-sm-12.col-md-6.col-lg-4.col-xl-4
            +field-validation('body.userprofile.middle_name', 'middleName', '[ validationCountry, "maxLength"]')
          div.col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6
            +field-validation('body.last_name_eng', 'lastNameEng', '["required", "alphaEN", "maxLength"]')(
              :label="`${$t('lastNameEng')} ${[TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.CAPTAIN].includes(body.userprofile.main_group) ? '*' : ''}`")
          div.col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6
            +field-validation('body.first_name_eng', 'nameEng', '["required", "alphaEN", "maxLength"]')(
              :label="`${$t('nameEng')} ${[TYPE_USER.MANAGER_CREWING, TYPE_USER.HEAD_CREWING, TYPE_USER.CAPTAIN].includes(body.userprofile.main_group) ? '*' : ''}`")
          div.col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6(v-if="body.userprofile.main_group === TYPE_USER.MEDICAL")
            +select-validation('body.userprofile.medical_institution','mappingMedicalInstitutions','medicalInstitution','"value"','["required"]')
          div.col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6(v-if="[TYPE_USER.DIRECTOR_OF_AGENCY_TRANSLATION, TYPE_USER.WORKER_OF_AGENCE_TRANSLATION].includes(body.userprofile.main_group)")
            +select-validation('body.userprofile.translation_company','translationAgency','translationAgency','"name"','["required"]')
          div.col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6
            +field-validation('body.username', 'login', '["required", "validLogin"]')(v-if="!REGISTRY_ROLE.includes(body.userprofile.main_group)")
            +field-validation('body.contact_info.email', 'email', '["required", "email"]')(v-else)

          div.col-xs-12.col-sm-12.col-md-12.col-lg-6.col-xl-6
            +field-validation('body.password', 'pass', '["required", "minLength", "maxLength"]')(
              :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isShowPassword ? 'text' : 'password'"
              @click:append="isShowPassword = !isShowPassword")
          div.col-xs-12.col-sm-12.col-md-6.col-lg-3.col-xl-3
            +select-validation('body.country','mappingCountry','country','labelValue','["required"]')(return-object)
          div.col-xs-12.col-sm-12.col-md-6.col-lg-3.col-xl-3
            +select-validation('body.region','region','region','labelValue','["required"]')(@input="getCity(body.region)")
          div(:class="body.userprofile.main_group === TYPE_USER.MEDICAL ? 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ' : 'col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3'")
            +select-validation('body.userprofile.city','cityList','city','labelValue','["required"]')(item-value='id' :loading="isLoadingCity")
          div(v-if="body.userprofile.main_group !== TYPE_USER.MEDICAL").col-xs-12.col-sm-12.col-md-6.col-lg-3.col-xl-3
            +select-validation('body.userprofile.branch_office','mappingAffiliate','affiliate','nameLang','["required"]')(item-value="id")
          div.col-xs-12.col-sm-12-col-md-6.col-lg-6.col-xl-6(v-if="[TYPE_USER.REPRESENTATIVE_OF_ETI].includes(body.userprofile.main_group)")
            +select-validation('body.userprofile.eti_institution','mappingTrainingPlace','nameInstitution','nameLang','["required"]')
          div.col-xs-12.col-sm-12-col-md-6.col-lg-6.col-xl-6(v-if="[TYPE_USER.SECRETARY_ATC].includes(body.userprofile.main_group)")
            +select-validation('body.userprofile.education_institution','mappingInstitutionList','nameInstitution','nameLang','["required"]')(
              @input="checkInstitutionAccessType"
            )
          div.col-xs-12.col-sm-12-col-md-6.col-lg-6.col-xl-6(v-if="[TYPE_USER.SECRETARY_ATC].includes(body.userprofile.main_group)")
            +select-validation('body.secretary_atc_profile.profile_type','listInstitutionType','accessType','nameLang','["required"]')(
              :readonly="isOnlyRead")
          //- +select-validation('body.userprofile.levels_qualification','coursesList','qualification','nameLang','["required"]')(v-if="[TYPE_USER.REPRESENTATIVE_OF_ETI].includes(body.userprofile.main_group)")

          div.col-xs-12.col-sm-12-col-md-6.col-lg-6.col-xl-6(
            v-if="[TYPE_USER.MANAGER_CREWING, TYPE_USER.SECRETARY_SC, TYPE_USER.HEAD_CREWING].includes(body.userprofile.main_group)")
            +select('body.userprofile.crewing_company','crewingCompaniesList','crewingCompany','nameLang')
          div.col-xs-12.col-sm-12-col-md-6.col-lg-6.col-xl-6(v-if="!REGISTRY_ROLE.includes(body.userprofile.main_group)")
            +field-validation('body.contact_info.email', 'email', '["required", "email"]')
          div.col-12.p-0
            div.col-xs-12.col-sm-12.col-md-6.d-flex.flex-column.align-start.p-0(
              v-if="[TYPE_USER.MANAGER_CREWING, TYPE_USER.SECRETARY_ATC, TYPE_USER.HEAD_CREWING].includes(body.userprofile.main_group)")
              div.w-100.p-0
                PhoneNumberField(v-model="body.contact_info.phone_number" :error-messages="$valid.validation($v.body.contact_info.phone_number, ['required'])").mb-6
              div.d-flex.w-100.p-0.pl-3
                div.w-25.p-0
                  +checkbox('isTelegramFlag', 'Telegram').mr-5
                PhoneNumberField(v-model="body.contact_info.telegram" v-if="isTelegramFlag" :error-messages="$valid.validation($v.body.contact_info.telegram, ['required'])").mb-6
              div.d-flex.w-100.p-0.pl-3
                div.w-25.p-0
                  +checkbox('isViberFlag', 'Vider').mr-5
                PhoneNumberField(v-model="body.contact_info.viber" v-if="isViberFlag" :error-messages="$valid.validation($v.body.contact_info.viber, ['required'])").mb-6
          div(v-if="body.userprofile.main_group === 'Голова групи'").col-xs-12.col-sm-12-col-md-6.col-lg-6.col-xl-6
            +select-validation('body.head_group_profile.group','listCrewingGroups','group','"name"', '["required"]')
          // now not used
          //- div.w-100
          //-   +checkbox('isRegistrationVariant', 'registerWithUbikey').w-50
          div.w-100.justify-center.pb-4
            v-btn(color="success" type="submit" :loading="isButtonLoader") {{ $t('registration') }}

          h5.text-center.card-title(v-if="isAddFingetTitle") {{ $t('fingerTite') }}
</template>

<script>
import { userRegistration } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { clearBody, replaceAllSymbols } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { REGISTRY_ROLE, SUCCESS_CODE, contacts, TYPE_USER, USER_USE_SIGN, TYPE_ACCESS_OF_SYSTEM
} from '@/configs/constants'
import { setTransliteration } from '@/plugins/transliteration'

const initBody = () => (
  {
    is_active: true,
    username: null,
    password: null,
    first_name: null,
    last_name: null,
    first_name_eng: null,
    last_name_eng: null,
    country: {
      value_abbr: 'UA'
    },
    region: null,
    userprofile: {
      medical_institution: null,
      main_group: null,
      city: null,
      middle_name: null,
      branch_office: null,
      eti_institution: null,
      education_institution: null,
      crewing_company: null,
      translation_company: null,
      contact_info: [],
      additional_data: '',
      language: 'UA'
    },
    head_group_profile: {
      group: null
    },
    secretary_atc_profile: {
      profile_type: null
    },
    contact_info: {
      phone_number: null,
      telegram: null,
      viber: null,
      email: null
    }
  }
)

export default {
  data () {
    return {
      body: initBody(),
      isTelegramFlag: false,
      isViberFlag: false,
      isRegistrationVariant: false,
      isAddFingetTitle: false,
      isButtonLoader: false,
      REGISTRY_ROLE,
      isShowPassword: false,
      codeOperator: {
        phone_number: {
          dial_code: '+380'
        },
        telegram: {
          dial_code: '+380'
        },
        viber: {
          dial_code: '+380'
        }
      },
      TYPE_USER,
      TYPE_ACCESS_OF_SYSTEM,
      isOnlyRead: false
    }
  },
  components: {
    AddUbikeyToLogin: () => import('./AddUbikeyToLogin/AddUbikeyToLogin.vue'),
    PhoneNumberField: () => import('@/components/atoms/PhoneNumberField')
  },
  validations () { return { body: userRegistration(this._data) } },
  computed: {
    ...mapState({
      labelValue: state => (state.main.lang === 'en') ? 'value_eng' : 'value',
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      isLoadingCity: state => state.directory.isLoadingCity,
      permissionsList: state => state.directory.registrationPermissionsList,
      mappingAffiliate: state => state.directory.affiliate,
      mappingTrainingPlace: state => state.directory.educationTraining,
      listInstitutions: state => state.directory.institution,
      listInstitutionType: state => state.directory.institutionTypes.filter(type => type.id !== TYPE_ACCESS_OF_SYSTEM.PLUSE_PAID),
      mappingInstitutionList () {
        let institutions = this.body.userprofile.main_group === TYPE_USER.SECRETARY_ATC ? this.listInstitutions : this.institutionByType(2)
        institutions = institutions.sort((a, b) => (a.is_red < b.is_red) ? 1 : -1)
        return institutions
      },
      crewingCompaniesList: state => state.crewing.crewingCompanies.results,
      listCrewingGroups: state => state.directory.crewingGroups.filter(group => !group.has_head_group),

      mappingCountry: state => state.directory.country,
      region () { return this.regionById(this.body?.country?.id) || [] },
      cityList: state => state.directory.city,
      mappingMedicalInstitutions: state => state.directory.medInstitution,
      translationAgency: state => state.backoffice.translationAgency.results,
      coursesList: state => state.directory.qualificationLevels.filter(el => el.type_NZ === 2)
    }),
    ...mapGetters(['doctorsByMedInstitutionID', 'regionById', 'institutionByType', 'getDirectoryObject']),
    validationCountry () { return `alpha${this.body?.country?.value_abbr}` }
  },
  // now not used
  watch: {
    'body.userprofile.main_group' (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === TYPE_USER.SECRETARY_ATC) this.getNameInstitution({ for_registration_atc: true })
        else if (newVal === 'Директор компанії перекладачів') this.getTranslationAgencies({ has_director: false })
        else if (newVal === 'Перекладач') this.getTranslationAgencies({ has_director: true })
      }
    },
    'body.last_name' (val) {
      this.body.last_name_eng = setTransliteration(val)
    },
    'body.first_name' (val) {
      this.body.first_name_eng = setTransliteration(val)
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'userRegistration', access: checkAccess('menuItem-newUser') })
  },
  methods: {
    ...mapActions(['getCity', 'setUser', 'registerFingerFirst', 'registerFingerSec', 'getNameInstitution',
      'getTranslationAgencies']),
    checkInstitutionAccessType (id) {
      if (this.getDirectoryObject({ value: 'institution', id })?.access_type === TYPE_ACCESS_OF_SYSTEM.FREE) {
        this.body.secretary_atc_profile.profile_type = TYPE_ACCESS_OF_SYSTEM.FREE
        this.isOnlyRead = true
      } else {
        this.body.secretary_atc_profile.profile_type = null
        this.isOnlyRead = false
      }
    },
    async createUser () {
      this.isButtonLoader = true
      if (this.$v.$invalid) {
        this.isButtonLoader = false
        return this.$v.$touch()
      }
      let body = { ...this.body }
      body.userprofile.contact_info = []
      Object.entries(clearBody(body.contact_info)).forEach(([key, value]) => {
        if (!body.userprofile.contact_info.length || body.userprofile.contact_info.find(contact => contact.type_contact === contacts[key].id)?.value !== value) {
          body.userprofile.contact_info.push({ type_contact: contacts[key].id, value: replaceAllSymbols(value, [' ', '(', ')']) })
        }
      })
      if (body.doctor) {
        body.doctor = body.doctor.split(' ')
        body.last_name = body.doctor[0]
        body.first_name = body.doctor[1]
        body.userprofile.middle_name = body.doctor[2]
      }
      if (REGISTRY_ROLE.includes(body.userprofile.main_group)) body.username = body.contact_info.email
      if (USER_USE_SIGN.includes(body.userprofile.main_group)) body.email = body.contact_info.email
      let deleteKeyBody = ['country', 'region', 'contact_info', 'doctor']
      deleteKeyBody.forEach(key => delete body[key])
      const response = await this.setUser({ body: { ...clearBody({ ...body }) } })
      if (SUCCESS_CODE.includes(response.code)) {
        this.isButtonLoader = false
        // now not used
        // if (this.isRegistrationVariant) {
        // this.registerFinger(response.data.id)
        // } else {
        this.$swal({
          icon: 'success',
          title: this.$t('userWasCreated'),
          text: this.$t('sentLetterForUser', { email: this.body.contact_info.email })
        })
        this.$v.$reset()
        this.body = initBody()
        // }
      }
      this.isButtonLoader = false
    },
    async registerFinger (id) {
      const response = this.registerFingerFirst()
      if (SUCCESS_CODE.includes(response.code) && id) {
        this.$swal(this.$t('putFingerOnKey'))
        this.registerFingerSecond(response.data.register_request, id)
      } else this.$notification.error('notify.title.error')
    },
    async registerFingerSecond (reg, id) {
      const regReq = {
        challenge: reg.registerRequests[0].challenge,
        version: reg.registerRequests[0].version
      }
      this.u2f.register(reg.appId, [regReq], [], async (keyAuthResponse) => {
        if (!keyAuthResponse.errorCode) {
          keyAuthResponse.user_id = id
          const response = await this.registerFingerSec({ body: keyAuthResponse })
          switch (response.data.status) {
            case 'created':
              this.$swal(this.$t('userWasCreated'))
              this.dataForm = initBody()
              this.$v.$reset()
              break
            case 'device exist':
              this.$notification.error('userExist')
              break
          }
        } else this.$notification.error('errorRegKey')
      })
    }
  }
}
</script>
